import React, { useMemo } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { GlobalValuesProvider } from "../globalValues"
import SEO from "../components/seo"
import { Slices } from "../Slices"
import { PageThemeColorProvider } from "../styles/themes"
import styled from "styled-components"

export const cmsPageFragment = graphql`
  fragment ContentfulCmsPageContent on ContentfulCmsPage {
    __typename
    browserPageTitle
    slug
    title
    metaDescription
    publishedAt
    pageThemeColor
    logoOnlyHeader
    teaserText {
      teaserText
    }
    slices {
      __typename
      ... on ContentfulAccordion {
        ...ContentfulAccordionSliceFragment
      }
       ... on ContentfulUnuCheckoutBuilder {
         ...ContentfulUnuCheckoutBuilderFragment
       }
      ... on ContentfulHubspotFormSlice {
        ...ContentfulHubspotFormSliceFragment
      }
      ... on ContentfulQuoteSlice {
        ...ContentfulQuoteSliceFragment
      }
      ... on ContentfulRichtextSlice {
        ...ContentfulRichtextSliceFragment
      }
      ... on ContentfulDividerSlice {
        ...ContentfulDividerSliceFragment
      }
      ... on ContentfulHeroMediaSlice {
        ...ContentfulHeroMediaSliceFragment
      }
      ... on ContentfulIntroSlice {
        ...ContentfulIntroSliceFragment
      }
      ... on ContentfulBannerSlice {
        ...ContentfulBannerSliceFragment
      }
      ... on ContentfulColumnTeaserSlice {
        ...ContentfulColumnTeaserSliceFragment
      }
      ... on ContentfulImageCarrouselSlice {
        ...ContentfulImageCarrouselSliceFragment
      }
      ... on ContentfulEditorialSlice {
        ...ContentfulEditorialSliceFragment
      }
      ... on ContentfulImageAndTextSlice {
        ...ContentfulImageAndTextSliceFragment
      }
      ... on ContentfulBigTeaserSlice {
        ...ContentfulBigTeaserSliceFragment
      }
      ... on ContentfulSharingSlice {
        ...ContentfulSharingSliceFragment
      }
      ... on ContentfulBigDisruptorSlice {
        ...ContentfulBigDisruptorSliceFragment
      }
      ... on ContentfulBigEditorialSlice {
        ...ContentfulBigEditorialSliceFragment
      }
      ... on ContentfulImageColumnsSlice {
        ...ContentfulImageColumnsSliceFragment
      }
      ... on ContentfulDualImageCarrouselSlice {
        ...ContentfulDualImageCarrouselSliceFragment
      }
      ... on ContentfulImageBackgroundCopySlice {
        ...ContentfulImageBackgroundCopySliceFragment
      }
      ... on ContentfulModal {
        ...ContentfulModalFragment
      }
      ... on ContentfulModelComparisonSlice {
        ...ContentfulModelComparisonSliceFragment
      }
      ... on ContentfulTechnicalSpecsSlice {
        ...ContentfulTechnicalSpecsSliceFragment
      }
      ... on ContentfulNewsletterSignUpSlice {
        ...ContentfulNewsletterSignUpSliceFragment
      }
      ... on ContentfulBackgroundImageParallaxSlice {
        ...ContentfulBackgroundImageParallaxSliceFragment
      }
      ... on ContentfulHeroSplashVideoSlice {
        ...ContentfulHeroSplashVideoSliceFragment
      }
      ... on ContentfulGarageFinderSlice {
        ...ContentfulGarageFinderSliceFragment
      }
      ... on ContentfulParallaxMediaSlice {
        ...ContentfulParallaxMediaSliceFragment
      }
      ... on ContentfulScooterModelSlice {
        ...ContentfulScooterModelSliceFragment
      }
      ... on ContentfulAnimatedMapSlice {
        ...ContentfulAnimatedMapSliceFragment
      }
      ... on ContentfulPostsSlice {
        ...ContentfulPostsSliceFragment
      }
    }
  }
`

export const query = graphql`
  query CMSPageQuery(
    $mainContentfulId: String!
    $contentful_id: String
    $node_locale: String
    $allAdditionalEntryIdsGlob: String!
  ) {
    allContentfulCmsPage(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        ...LinkableCmsPageFragment
      }
    }
    contentfulCmsPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      ...ContentfulCmsPageContent
    }

    allAdditionalEntries: allContentfulEntry(
      filter: {
        contentful_id: { glob: $allAdditionalEntryIdsGlob }
        node_locale: { eq: $node_locale }
      }
    ) {
      nodes {
        ... on ContentfulPost {
          __typename
          browserPageTitle
          contentful_id
          slug
          title
          teaserText {
            teaserText
          }
          teaserImage {
            gatsbyImageData
          }
        }
        ... on ContentfulCmsPage {
          __typename
          browserPageTitle
          contentful_id
          slug
          title
        }
      }
    }

    ...GlobalValues
    ...DefaultMainValues
  }
`

const PageSlicesContainer = styled.main``

// Display the title, date, and content of the Post
const CMSPageBody = ({ page, additionalEntriesBySlice, locale }) => {
  return (
    <PageSlicesContainer>
      <Slices
        slices={page.slices}
        locale={locale}
        page={page}
        additionalEntriesBySlice={additionalEntriesBySlice}
      />
    </PageSlicesContainer>
  )
}

const CMSPage = ({ data, pageContext }) => {
  const page = data.contentfulCmsPage
  const {
    language,
    country,
    footerData,
    pageMeta,
    additionalEntryReferencesBySlice,
  } = pageContext
  const allAdditionalEntries = data.allAdditionalEntries.nodes
  const additionalEntriesBySlice = useMemo(() => {
    const result = {}

    for (const sliceId of Object.keys(additionalEntryReferencesBySlice)) {
      result[sliceId] = allAdditionalEntries.filter(({ id }) =>
        additionalEntryReferencesBySlice[sliceId].find(
          sliceEntryReference => sliceEntryReference.id === id
        )
      )
    }

    return result
  }, [additionalEntryReferencesBySlice, allAdditionalEntries])

  return (
    <GlobalValuesProvider value={data.contentfulGlobal}>
      <PageThemeColorProvider colorId={page.pageThemeColor}>
        <Layout
          language={language}
          country={country}
          mainData={data.contentfulMain}
          footerData={footerData}
          localizedNodes={data.allContentfulCmsPage.nodes}
          logoOnlyHeader={page.logoOnlyHeader}
        >
          <SEO
            title={page.browserPageTitle ?? page.title}
            description={page.metaDescription}
            localizedNodes={data.allContentfulCmsPage.nodes}
            pageMeta={pageMeta}
          />
          <CMSPageBody
            page={page}
            locale={pageContext.node_locale}
            additionalEntriesBySlice={additionalEntriesBySlice}
          />
        </Layout>
      </PageThemeColorProvider>
    </GlobalValuesProvider>
  )
}

export default CMSPage
